<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            :loading="loading['applications/list']"
            @view="onView"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { ApplicationModel } from '@/modules/applications/models/application-model';
    const { fields } = ApplicationModel;

    export default {
        name: 'ApplicationsListTable',
        data() {
            return {
                presenter: ApplicationModel.presenter,
                actions: [
                    {
                        name: 'view',
                        icon: 'eye',
                        tooltipText: this.$t('GENERAL.ACTIONS.VIEW'),
                        customIcon: false,
                        type: 'primary'
                    },
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'applications/list/rows',
                loading: 'shared/loading',
            }),
            fields() {
                return [
                    fields.created_by,
                    fields.beneficiary.extendField({ customField: true, portalTarget: 'beneficiary-custom-field'}),
                    fields.identification_number,
                    fields.application_type_name,
                    fields.application_date.extendField({ bold: true}),
                    fields.locality.extendField({ customField: true, portalTarget: 'locality-custom-field'}),
                    fields.is_paid.extendField({ booleanType: true }),
                    fields.status.extendField({ labelType: true }),
                ];
            },
        },
        methods: {
            ...mapActions({
                selectRecord: 'applications/form/find',
            }),
            async onView(data) {
                if (!data?.item?.id) return;
                const application = await this.selectRecord({ id: data.item.id, loadStaff: true});
                const routeName =  application?.status === 'draft' ? 'applicationEdit' : 'applicationView';
                this.$router.push({
                    name: routeName,
                    params: {
                        id: data.item.id,
                    },
                });
            },
        }
    };
</script>
