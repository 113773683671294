<template>
    <st-filters-pagination
        :perPage=5
        stateModule="applications/list"
        :filters="filters"
        :total="total"
        @change="doFilter"
        :header="$t('DASHBOARD.RECENT_APPLICATIONS.HEADER')"
        :showFilters="true"
        :showPagination="false"
        :moreFilters="false"
        :showErase="false"
    >
        <template #filters-toolbar>
            <slot name="toolbar">
                <st-button
                    variant="primary"
                    :callback="goToApplications"
                >
                    <i class="far fa-eye"></i>
                    {{ $t('DASHBOARD.RECENT_APPLICATIONS.BUTTON.VIEW_ALL_APPLICATIONS') }}
                </st-button>
            </slot>
        </template>
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ApplicationModel } from '@/modules/applications/models/application-model';
const { fields, statuses } = ApplicationModel;
export default {
    name: 'RecentApplicationsListFilter',
    props: {
        isStaff: {
            type: Boolean,
            required: true
        },
    },
    data() {
            return {
                filters: [],
                fields,
                statuses
            }
        },
    computed: {
        ...mapGetters({
            total: 'applications/list/total',
            currentUser: 'auth/currentUser',
        }),
    },
    created() {
        this.fields.status.options = this.statuses;
    },
    methods: {
        ...mapActions({
            doFetch: 'applications/list/doFetch',
        }),
        doFilter(params) {
            const payload = {
                loadStaff: this.isStaff,
                params,
            };

            this.doFetch(payload);
        },
        goToApplications() {
            this.$router.push({ name: 'applications' });
        }
    },
};
</script>
