<template>
    <div>
        <st-page customClass="pb-8 mt-2" :showHeader="false">
            <recent-applications-list-filter :isStaff="isStaff">
                <recent-applications-list-table/>
            </recent-applications-list-filter>
        </st-page>
        <portal to="beneficiary-custom-field">
            <template slot-scope="{data}">
                <ApplicationTableCustomField
                    fieldType="beneficiary"
                    :items="data.item.form.beneficiary_users"
                />
            </template>
        </portal>
        <portal to="locality-custom-field">
            <template slot-scope="{data}">
                <ApplicationTableCustomField
                    fieldType="locality"
                    :items="data.item.form.real_estate_target"
                />
            </template>
        </portal>
    </div>
</template>

<script>
import RecentApplicationsListFilter from './RecentApplicationsListFilter';
import RecentApplicationsListTable from './RecentApplicationsListTable';
import ApplicationTableCustomField from '@/modules/applications/components/ApplicationTableCustomField';
export default {
    name: 'DashboardRecentApplications',
    components: {
        RecentApplicationsListFilter,
        RecentApplicationsListTable,
        ApplicationTableCustomField
    },
    props: {
        isStaff: {
            type: Boolean,
            default: true
        },
    },
}
</script>
